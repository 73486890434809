import * as React from 'react';
import { graphql } from 'gatsby';
import { Box } from '@chakra-ui/react';
import queryString from 'query-string';
import SeoComponent from '../../components/SeoComponent';

/* SECTIONS */
import Hero from '../../sections/Hero';
import { ProductsListFilter } from '../../sections/ProductsListFilter';
import DropdownEffectHome from '../../sections/DropdownEffectHome';

/* IMPORT APP CONTEXT */
import { AppContext } from '../../context';

function ShopCategory(props) {
  const { data, location, pageContext } = props;
  const hasSubcategories = pageContext.category ? pageContext.category.subcategories.length > 0 : false;
  
  const appContext = React.useContext(AppContext);

  const [pageMounted, setPageMounted] = React.useState(false);

  /* CATEGORY AND SUBCATEGORY PAGES FILTER */
  const pageInfoToUse = pageContext.category 
    ? pageContext.category.page
    : pageContext.subcategory 
      ? pageContext.subcategory.page
      : null;

  const heroData = pageInfoToUse.sections.find(item => item._type === 'hero');
  const seoImg = pageInfoToUse?.seoImage?.image || heroData?.heroImg?.bgImage;

  /* CATEGORY AND SUBCATEGORY PRODUCTS LIST */
  const productsToFilter = pageContext.categoryName 
    ? data.byCategory.nodes
    : pageContext.subcategoryName 
      ? data.bySubcategory.nodes
      : null

  // console.log('ShopCategory: ', productsToFilter);

  /* REFS */
  const productsListRef = React.useRef(null);

  /* CATEGORY AND SUBCATEGORY PAGES FILTER */
  const slashQuery = pageContext.category 
    ? pageContext.category.slug.current
    : pageContext.subcategory 
      ? [pageContext.subcategory.category.slug.current, pageContext.subcategory.slug.current]
      : null

  // console.log('slashQuery: ', slashQuery);

  /* SHOP ALL PAGE FILTER */
  const qsObject = queryString.parse(location.search, { arrayFormat: 'comma' });

  Object.keys(qsObject).map((key) => {
    if (typeof qsObject[key] === 'string') {
      qsObject[key] = [qsObject[key]];
    }
  });
  
  const [allStates, setAllStates] = React.useState(null);

  /* PAGE LOADED */
	React.useEffect(() => {
    /* SCROLL TO TOP */
    appContext.pageTopRef.current.scrollIntoView();

    const queryStates = pageContext.categoryName 
      ? data.byCategory.group
      : pageContext.subcategoryName 
        ? data.bySubcategory.group
        : null

    const statesList = queryStates.map(item => {
      const stateValue = item.fieldValue.trim().toLowerCase().replace('-', ' ');

      return {
        name: stateValue, 
        slug:{current: stateValue},
      };
    });

    const orderedStates = statesList.sort(function (a, b) {
      if (a.slug.current > b.slug.current) {
        return 1;
      }
      if (a.slug.current < b.slug.current) {
        return -1;
      }
      
      return 0;
    });

    setAllStates(orderedStates);
    setPageMounted(true);
    
    return () => {
      setPageMounted(false);

    };
	}, []);

  return (
    <>
      {pageInfoToUse && (
        <SeoComponent
          title={pageInfoToUse?.seoTitle || ''}
          description={pageInfoToUse?.seoDescription || ''}
          imageUrl={seoImg?.asset?.url}
        />
      )}

      <Box maxW="100%">
        {/* HERO */}
        {pageInfoToUse && (
          <Hero
            subheader={heroData.accentHeader.headerText}
            header={heroData.mainHeader.headerText}
            heroBgImg={heroData.heroImg}
          />
        )}

        {pageMounted && allStates && (
          <>
            {/* PRODUCTS LIST AND FILTER */}
            <ProductsListFilter 
              refToUse={productsListRef}
              location={location} 
              filter={qsObject}
              slashQuery={slashQuery}
              hasSubcategories={hasSubcategories}
              topBarFilter={pageContext.shopTopBarItems}
              isCollectionPage={slashQuery}
              currentPage={pageContext.heroHeader}
              productsToFilter={productsToFilter}
              allStates={allStates}
              productsBaseRoute={pageContext.productsBaseRoute}
            />
    
            {/* DROPDOWN */}
            <DropdownEffectHome onShopPage={true}	pageTopRef={productsListRef} />
          </>
        )}


      </Box>
    </>
  )
}

export const query = graphql`
  query ($categoryName:String, $subcategoryName:String) {
    byCategory: allSanityProduct(filter: {category: {slug: {current: {eq: $categoryName}}}}) {
      nodes {
        isPublic
        name
        seoTitle
        seoDescription
        seoImage {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        collectionImage {
          asset {
            gatsbyImageData(placeholder: NONE, width: 276, height: 276)
          }
        }
        category {
          name
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        subcategory {
          name
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        infoPerState {
          isDefault
          useDefaultDetails
          useDefaultCompoundInfo
          useDefaultAdditionalInfo
          _rawDetails
          _rawAdditionalInfo
          warning
          state {
            name
            abbrevation
            slug {
              current
            }
          }
          productImage {
            asset {
              gatsbyImageData(width: 668, height: 535, fit: MAX, placeholder: NONE)
            }
          }
          details {
            children {
              _type
              marks
              text
            }
            _type
            list
            style
          }
          additionalInfo {
            _type
            style
            list
            children {
              text
              marks
            }
          }
          compoundInformation {
            compound {
              name
              slug {
                current
              }
            }
            compoundPerPKG
            compoundPerPiece
          }
        }
        effects {
          name
          slug {
            current
          }
        }
        lineages {
          name
          slug {
            current
          }
        }
        nutriInfo
        ingredients
        unitsPerPackage
        durationImage {
          asset {
            gatsbyImageData(width: 172, height: 111, placeholder: NONE)
          }
        }
        typeOfStore {
          name
          slug {
            current
          }
        }
      }
      group(field: infoPerState___state___name) {
        fieldValue
      }
    }
    bySubcategory: allSanityProduct(filter: {subcategory: {slug: {current: {eq: $subcategoryName}}}}) {
      nodes {
        isPublic
        name
        seoTitle
        seoDescription
        seoImage {
          image {
            asset {
              url
            }
          }
        }
        slug {
          current
        }
        collectionImage {
          asset {
            gatsbyImageData(placeholder: NONE, width: 276, height: 276)
          }
        }
        category {
          name
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        subcategory {
          name
          ctaRoute {
            route
          }
          slug {
            current
          }
        }
        infoPerState {
          isDefault
          useDefaultDetails
          useDefaultCompoundInfo
          useDefaultAdditionalInfo
          _rawDetails
          _rawAdditionalInfo
          warning
          state {
            name
            abbrevation
            slug {
              current
            }
          }
          productImage {
            asset {
              gatsbyImageData(width: 668, height: 535, fit: MAX, placeholder: NONE)
            }
          }
          details {
            children {
              _type
              marks
              text
            }
            _type
            list
            style
          }
          additionalInfo {
            _type
            style
            list
            children {
              text
              marks
            }
          }
          compoundInformation {
            compound {
              name
              slug {
                current
              }
            }
            compoundPerPKG
            compoundPerPiece
          }
        }
        effects {
          name
          slug {
            current
          }
        }
        lineages {
          name
          slug {
            current
          }
        }
        nutriInfo
        ingredients
        unitsPerPackage
        durationImage {
          asset {
            gatsbyImageData(width: 172, height: 111, placeholder: NONE)
          }
        }
        typeOfStore {
          name
          slug {
            current
          }
        }
      }
      group(field: infoPerState___state___name) {
        fieldValue
      }
    }
  }
`;

export default ShopCategory;